<template>
  <v-card :loading="loading" flat>
    <v-card-text>
      <v-simple-table>
        <template v-slot:default>
          <thead>
          <tr>
            <td>
              <v-btn @click="loadData" color="primary" small fab>
                <v-icon>mdi-refresh</v-icon>
              </v-btn>
            </td>
          </tr>
          <tr>
            <th class="text-center">File Name</th>
            <th class="text-center">Submitted By</th>
            <th class="text-center">Created At</th>
            <th class="text-center">Updated At</th>
            <th class="text-center">Submitted At</th>
            <th class="text-center">Un-Archive</th>
          </tr>
          </thead>
          <tbody>
          <tr v-for="item in submittedFiles" :key="item.id">
            <td class="text-center">{{ item.file_name }}</td>
            <td class="text-center">{{ item.submitted }}</td>
            <td class="text-center">{{ formattedDate(item.original_created_at) }}</td>
            <td class="text-center">{{ formattedDate(item.original_updated_at) }}</td>
            <td class="text-center">{{ formattedDate(item.original_deleted_at) }}</td>
            <td class="text-center">
              <v-btn @click="unArchiveFile(item.file_name)"
                     elevation="2" color="secondary" :loading="loadingArchive">
                Un-Archive
              </v-btn>
            </td>
          </tr>
          </tbody>
        </template>
      </v-simple-table>
    </v-card-text>
  </v-card>
</template>

<script>
import ccrsApi from "@/lib/ccrs-api";
import dateFormatter from "@/lib/dateFormatter";
export default {
  name: "ArchivedFiles.vue",
  mounted() {
    this.loadData();
  },
  data: () => ({
    loading: false,
    loadingDownload: false,
    loadingArchive: false,
    accessFile: null,
    fileData: [],
    submittedFiles: [],
  }),
  methods: {
    async loadData() {
      this.loading = true;
      let query = await ccrsApi.get('/v2/labNewFiles/archives');
      this.submittedFiles = query.data;
      this.loading = false;
    },
    async unArchiveFile(fileName) {
      this.loadingArchive = true;
      await ccrsApi.get('/v2/labNewFiles/restore/' + fileName);
      this.loadData();
      this.loadingArchive = false;
    },
    setAccessFile(fileName) {
      this.accessFile = fileName;
    },
    formattedDate(dateString) {
      return dateFormatter.formatDate(dateString);
    },
  },
}
</script>

<style scoped>

</style>